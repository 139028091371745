















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Gvd } from '@/store/modules/gvd'
import GvdPublishModal from './GvdPublish.modal.vue'
import { EventBus } from '@/utils/event-bus'

@Component({
  components: {
    GvdPublishModal
  }
})
export default class GvdAdminHeader extends Vue {
  productName: string = this.$store.getters.getActiveProduct.name
  @Prop() gvd?: Gvd
  @Prop() breadcrumbItemOverride?: any[]
  breadcrumbItems: any[] = [
    {
      text: 'Home',
      to: { name: 'home' }
    },
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      html: `${this.productName}`,
      to: { name: 'product-admin' }
    },
    {
      html: this.gvd ? `${this.gvd.title} (v${this.gvd.semVersion})` : '',
      to: { name: 'gvd-admin' }
    }
  ]

  get hasChanges () {
    return this.gvd ? this.gvd.hasChanges : false
  }

  openPublishModal () {
    this.$bvModal.show('gvd-publish-modal')
  }

  publish (updateDetails: any) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to publish?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      this.$bvModal.hide('gvd-publish-modal')
      this.$store.dispatch('publishGvd', { gvd: this.gvd, updateDetails: updateDetails }).then((newDraft: Gvd) => {
        EventBus.$emit('hide-loader')
        this.$router.push({ name: 'gvd-admin', params: { productKey: this.$store.getters.getActiveProduct.key, gvdKey: newDraft.key } })
        this.$bvModal.msgBoxOk('Publish complete', {
          size: 'sm',
          buttonSize: 'sm',
          okTitle: 'OK',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
      }).catch(() => {
        EventBus.$emit('hide-loader')
        this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
      })
    })
  }
}
