












import { Component, Vue } from 'vue-property-decorator'
import GvdViewer from './@components/GvdViewer.vue'
import GvdAdminHeader from './@components/GvdAdminHeader.vue'
import store from '@/store/store'
import { Gvd } from '@/store/modules/gvd'
import { Product } from '@/store/modules/product'

@Component({
  components: {
    GvdViewer,
    GvdAdminHeader
  }
})
export default class GvdAdminDashboard extends Vue {
  gvd: Gvd = this.$store.getters.getActiveGvd
  documentError?: string = undefined

  get url () {
    return `${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/files/gvds/${this.gvd ? this.gvd.key : '-'}.pdf`
  }

  async mounted () {
    await this.$store.dispatch('loadResourceTypes')
    // load resources so that tab menu can update acordingly
    this.gvd = this.$store.getters.getActiveGvd
  }

  onDocumentErrored (e: any) {
    this.documentError = e.text
  }

  async beforeRouteUpdate (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.gvdKey) {
      if (!_product.gvds || !_product.gvds.length) {
        return next(`/admin/product/${_product.key}`)
      }
      return next(`/admin/product/${_product.key}/gvd/${_product.gvds[0].key}`)
    }
    const _gvd: Gvd = await store.dispatch('loadGvdAdmin', to.params.gvdKey)
    if (_gvd) {
      await store.dispatch('loadGvdSections', _gvd.gvdSections)
      await store.dispatch('loadGvdPages', _gvd.gvdSections)
      await store.dispatch('loadProductResources', to.params.productKey)
      this.gvd = this.$store.getters.getActiveGvd
      return next()
    }
    return next(`/admin/product/${_product.key}`)
  }

  async beforeRouteEnter (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.gvdKey) {
      if (!_product.gvds || !_product.gvds.length) {
        return next(`/admin/product/${_product.key}`)
      }
      return next(`/admin/product/${_product.key}/gvd/${_product.gvds[0].key}`)
    }
    const _gvd: Gvd = await store.dispatch('loadGvdAdmin', to.params.gvdKey)
    if (_gvd) {
      await store.dispatch('loadGvdSections', _gvd.gvdSections)
      await store.dispatch('loadGvdPages', _gvd.gvdSections)
      await store.dispatch('loadProductResources', to.params.productKey)
      return next()
    }
    return next(`/admin/product/${_product.key}`)
  }
}
