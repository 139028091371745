





















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import GvdResourceList from '@/views/products/gvds/@components/GvdResourceList.vue'
import GvdAssignResourcesToPage from './GvdAssignResourcesToPage.modal.vue'
import Resources, { ResourceType } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'

@Component({
  components: {
    GvdResourceList,
    GvdAssignResourcesToPage
  }
})
export default class GvdManageResources extends Vue {
  show: boolean = false
  @Prop(String) currentPageKey!: string
  @Prop() currentPageResources!: Resources[]
  @Prop() permittedMarkets!: Market[]

  get resourceCount () {
    return this.currentPageResources ? this.currentPageResources.length : 0
  }

  get resourceTypes () {
    return this.$store.getters.getResourceTypes.sort((a: ResourceType, b: ResourceType) => {
      return (a.name === 'References' || a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
    })
  }

  toggleResources (): void {
    this.show = !this.show
  }

  async openResourceAssignmentModal (resourceTypeKey: string): Promise<void> {
    // console.log('open model', resourceTypeKey)
    // load resources to for assignment modal - note: we don't want drafts on purpose
    await this.$store.dispatch('loadProductResources', this.$store.getters.getActiveProduct.key)
    this.$bvModal.show('assign-resources-modal')
  }

  @Emit('addResourceToPage')
  addResourceToPage (data: any) {
    return data
  }

  @Emit('removeResourceFromPage')
  removeResourceFromPage (data: any) {
    return data
  }
}
