















































































import PDFFindControls from '@/components/pdfviewer/PDFFindControls.vue'
import PDFPaginator from '@/components/pdfviewer/PDFPaginator.vue'
import PDFReturnToTop from '@/components/pdfviewer/PDFReturnToTop.vue'
import PDFZoom from '@/components/pdfviewer/PDFZoom.vue'
import PDFPrint from '@/components/pdfviewer/PDFPrint.vue'
import PDFViewer from '@/components/pdfviewer/PDFViewer.vue'
import GvdSectionSelect from '@/views/products/gvds/@components/GvdSectionSelect.vue'
import GvdDownloader from '@/views/products/gvds/@components/GvdDownloader.vue'
import GvdResources from '@/views/products/gvds/@components/GvdResources.vue'
import GvdReferences from '@/views/products/gvds/@components/GvdReferences.vue'
import GvdManageResources from '@/views/admin/products/gvds/@components/GvdManageResources.vue'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Gvd, GvdSection, GvdPage } from '@/store/modules/gvd'
import { Resource } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'
import { EventBus } from '@/utils/event-bus'
import store from '@/store/store'

import { PDFViewer as pdfjsViewer } from 'pdfjs-dist/lib/web/pdf_viewer'
import { PDFLinkService } from 'pdfjs-dist/lib/web/pdf_link_service'
import { PDFFindController } from 'pdfjs-dist/lib/web/pdf_find_controller'
// import { getGlobalEventBus } from 'pdfjs-dist/lib/web/ui_utils'

@Component({
  components: {
    PDFFindControls,
    PDFPaginator,
    PDFReturnToTop,
    PDFZoom,
    PDFPrint,
    PDFViewer,
    GvdSectionSelect,
    GvdDownloader,
    GvdResources,
    GvdReferences,
    GvdManageResources
  }
})
export default class GvdViewer extends Vue {
  @Prop() gvd?: Gvd

  url: string = ''
  scale: number = 1
  optimalScale: number = 4
  fit?: any = 'auto'
  width: any = 'auto'
  // minWidth: number = 0
  pageCount?: number = 0
  isPreviewEnabled: boolean = false
  searchActive: boolean = false
  gvdSectionOpts: any[] = []
  gvdSections: GvdSection[] = []
  currentPageNumber: number = 1
  currentGvdSection?: GvdSection
  currentGvdSectionKey: string = '' // not computed because it's 2-way bound to gvdSectionSelect component
  currentPage?: GvdPage
  currentPageKey: string = ''
  currentPageResources: Resource[] = []
  permittedMarkets: Market[] = []
  allReferences: Resource[] = []
  pdfLinkService?: any = null
  pdfFindController?: any = null
  docIsLoaded: Boolean = false

  async mounted () {
    // eslint-disable-next-line new-cap
    const _viewerInstance = new pdfjsViewer({
      container: document.getElementById('MainPdfViewer')
    })
    this.pdfLinkService = new PDFLinkService({ eventBus: _viewerInstance.eventBus })

    this.pdfFindController = new PDFFindController({
      linkService: this.pdfLinkService,
      eventBus: _viewerInstance.eventBus
    })
    this.pdfLinkService.setViewer(this)

    await this.initPdf()
  }

  async initPdf () {
    if (!this.gvd) {
      return
    }
    // only act if the route/gvd has actualy changed
    const _newUrl = `${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/files/gvds/${this.gvd.key}.pdf`
    if (this.url === _newUrl) {
      return
    }
    this.url = _newUrl
    this.gvdSections = this.gvd.gvdSections || []
    this.currentPageNumber = 1
    this.currentGvdSection = undefined
    this.currentPage = undefined
    this.currentPageKey = ''
    this.currentPageResources = []
    this.gvdSectionOpts = []
    this.currentGvdSectionKey = ''
    this.permittedMarkets = this.gvd.markets || []
    if (this.gvdSections && this.gvdSections.length) {
      this.currentGvdSection = this.gvdSections[0]
      this.currentGvdSectionKey = this.currentGvdSection.key // not computed because it's 2-way bound to gvdSectionSelect component
      if (this.currentGvdSection.gvdPages && this.currentGvdSection.gvdPages.length) {
        this.currentPage = this.currentGvdSection.gvdPages[0]
        this.currentPageKey = this.currentPage.key
        this.currentPageResources = this.currentPage.resources
        this.gvdSectionOpts = this.gvdSections.map((s: GvdSection) => {
          return {
            text: s.title,
            value: s.key
          }
        })
      }
    }

    this.allReferences = store.getters.getAllDraftProductResources.filter((resource: Resource) => {
      // const _hasRefNum = resource.gvdResourceNumbers.length
      return resource.resourceType && (resource.resourceType.name === 'References')
    }).sort((a: Resource, b: Resource) => {
      const _aResNum = (a.gvdResourceNumbers && a.gvdResourceNumbers.length) ? a.gvdResourceNumbers[0].number : -1
      const _bResNum = (b.gvdResourceNumbers && b.gvdResourceNumbers.length) ? b.gvdResourceNumbers[0].number : -1
      if (_aResNum > _bResNum && (_bResNum > 0)) {
        return 1
      } else if (_aResNum < _bResNum && (_aResNum > 0)) {
        return -1
      }

      // else sort by name
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
    })
  }

  floor (value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.floor(value * multiplier) / multiplier
  }

  loadPageByKey (key: string) {
    if (!key || !key.length) {
      return
    }
    let pageNo = 1;
    // if (this.$route.query.page !== undefined) {
    (this.gvdSections as GvdSection[]).forEach((section: GvdSection) => {
      const page = section.gvdPages.find((page: GvdPage) => {
        return page.key === key
      })
      if (page) {
        pageNo = page.pageNo
      }
    })
    // }
    this.updateCurrentPage(pageNo)
  }

  widthChanged (width: any) {
    this.width = width
    // this.minWidth = this.minWidth || width
  }

  onDocumentRendered () {
    // if we have a page key, load it. Otherwise make sure we're on the first page.
    if (this.$route.query.page) {
      this.loadPageByKey(this.$route.query.page as string)
    } else {
      this.updateCurrentPage(1)
      // (document.getElementById('app') as HTMLElement).scrollTop = 0
    }
    this.docIsLoaded = true
    this.$emit('document-rendered', this.url)
  }

  onDocumentErrored (e: any) {
    this.$emit('document-errored', e)
  }

  updateScale ({ scale, isOptimal = false }: any) {
    const roundedScale = this.floor(scale, 2)
    if (isOptimal) this.optimalScale = roundedScale
    this.scale = roundedScale
  }

  updateFit (fit: string) {
    this.fit = fit
  }

  updatePageCount (pageCount: number) {
    this.pageCount = pageCount
  }

  updateCurrentPage (pageNumber: number) {
    if (this.currentPageNumber === pageNumber) {
      this.$router.replace({ query: { '': null } }).catch(() => {})
      // return
    }
    this.currentPageNumber = pageNumber
    this.$store.dispatch('setActiveGvdPage', pageNumber).then((gvdPage: GvdPage) => {
      this.currentPage = gvdPage || undefined
      this.currentPageKey = gvdPage ? gvdPage.key : ''
      this.currentPageResources = gvdPage ? gvdPage.resources : []
      if (this.currentPage && this.currentPage.key !== this.$route.query.page) {
        this.$router.replace({ query: { '': null } }).catch(() => {})
      }
      this.$emit('page-focus', this.currentPageNumber)
    })
  }

  togglePreview () {
    this.isPreviewEnabled = !this.isPreviewEnabled
  }

  isPageVisible (pageNo: number) {
    return this.currentPageNumber === pageNo
  }

  findStart () {
    this.searchActive = true
  }

  findEnd () {
    this.searchActive = false
  }

  // admin methods:
  addResourceToPage (data: any) {
    EventBus.$emit('show-loader')
    this.$store.dispatch('addResourceToGvdPage', data).then(result => {
      if (!result || !result.data || !this.gvd) {
        return
      }
      this.currentPage = result.data

      const _section = this.gvd.gvdSections.find(s => {
        return s.key === this.currentGvdSectionKey
      })
      if (_section && _section.gvdPages.length) {
        const _page = _section.gvdPages.find(p => {
          return p.key === this.currentPageKey
        })
        if (_page) {
          _page.resources = this.currentPage ? this.currentPage.resources : []
          this.currentPageResources = _page.resources
        }
      }
      this.gvd.hasChanges = true
      this.$store.dispatch('updateGvd', this.gvd).then(() => {
        EventBus.$emit('hide-loader')
      })
    })
  }

  removeResourceFromPage (data: any) {
    EventBus.$emit('show-loader')
    this.$store.dispatch('removeResourceFromGvdPage', data).then(result => {
      if (!result || !result.data || !this.gvd) {
        return
      }
      this.currentPage = result.data

      const _section = this.gvd.gvdSections.find(s => {
        return s.key === this.currentGvdSectionKey
      })
      if (_section && _section.gvdPages.length) {
        const _page = _section.gvdPages.find(p => {
          return p.key === this.currentPageKey
        })
        if (_page) {
          _page.resources = this.currentPage ? this.currentPage.resources : []
          this.currentPageResources = _page.resources
        }
      }
      this.gvd.hasChanges = true
      this.$store.dispatch('updateGvd', this.gvd).then(() => {
        EventBus.$emit('hide-loader')
      })
    })
  }

  @Watch('gvd')
  gvdChanged () {
    this.initPdf()
  }

  @Watch('url')
  urlChanged () {
    this.currentPageNumber = 1
  }

  @Watch('currentPageNumber')
  currentPageNumberChanged () {
    const _newActiveSection = (this.gvdSections as GvdSection[]).find((s: any) => s.gvdPages.some((p: any) => p.pageNo === this.currentPageNumber))
    this.currentGvdSectionKey = _newActiveSection ? _newActiveSection.key : ''
  }

  @Watch('currentGvdSectionKey')
  currentGvdSectionKeyChanged () {
    const _newActiveSection = (this.gvdSections as GvdSection[]).find((s: any) => s.key === this.currentGvdSectionKey)
    if (_newActiveSection && _newActiveSection.gvdPages.length) {
      this.$store.dispatch('setActiveGvdSection', _newActiveSection)
      const _pageInSection = _newActiveSection.gvdPages.find((p: any) => {
        return p.pageNo === this.currentPageNumber
      })
      this.updateCurrentPage(_pageInSection ? _pageInSection.pageNo : _newActiveSection.gvdPages[0].pageNo)
    }
  }

  @Watch('$route.query.page', { immediate: true, deep: true })
  routeQueryPageChanged () {
    this.loadPageByKey(this.$route.query.page as string)
  }
}
